import React from "react";

const Pagination = (props) => {
  const { final_data, blogs, total_pages, page, changePage } = props;

  return (
    <div className="d-flex justify-content-md-start justify-content-center align-items-center mt-5">
      {/* <p className="">
        Show {final_data?.length} of {blogs?.length} items.
      </p> */}
      <div className="d-flex justify-content-md-start justify-content-center flex-wrap">
        <div
          className={`bd-number-pagination  me-3 mt-2`}
          onClick={() => changePage(page - 1)}
        >
          <i className="bi bi-chevron-left"></i>
        </div>

        {[...Array(total_pages)].map((_, pagecount) => {
          pagecount += 1;
          return (
              <div
                key={pagecount}
                className={`bd-number-pagination mx-1 mt-2 pe-md-1 cursor-pointer ${
                  page === pagecount && "activepage"
                }`}
                onClick={() => changePage(pagecount)}
              >
                {pagecount < 10 ? "0" + pagecount : pagecount}
              </div>
          );
        })}

        <div
          className={`bd-number-pagination  ms-3 mt-2`}
          onClick={() => changePage(page + 1)}
        >
          <i className="bi bi-chevron-right"></i>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
