import React from "react";
import "../../assets/css/Banner.css";
import Image3 from "../../assets/Images/image-3.jpg";
import {  Config } from "../../config";

const ProjectBanner = ({
  projectname,
  area,
  price,
  size,
  config,
  status,
  rera,
  backImage,
  ProjectStatus
}) => {
    
  return (
    <div>
      <div
        className="banner-header section-padding valign bg-img bg-fixed project-banner"
        data-overlay-dark="4"
        data-background="img/slider/1.jpg"
        style={{ backgroundImage: `url(${backImage ? `${Config.imageUrl}/${backImage}` : Image})`, backgroundRepeat:"no-repeat", backgroundSize:"cover" }}
      >
        <div className="">
          <div className="container">
            <div className="row banner-top justify-space-between align-items-center">
              <div className="col-md-6 caption mt-90 ">
                <h1>{projectname}</h1>
                <h5>{area}</h5>
              </div>
              <div
                className="col-md-5 project-info d-flex"
                data-aos="fade-left"
              >
                <div className="box">
                  <h6>
                    Price : <span>{price}</span>
                  </h6>
                  <h6>
                    Size : <span>{size}</span>
                  </h6>
                  <h6>
                    Configuration : <span>{config}</span>
                  </h6>
                  <h6>
                    Category : <span>{status}</span>
                  </h6>
                  <h6>
                    RERA No : <span>{rera}</span>
                  </h6>
                  <h6>
                    Status : <span>{ProjectStatus}</span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectBanner;
