import React, { useEffect, useState } from "react";
import Banner from "../../Common/Banner";
import { Link } from "react-router-dom";
import { axiosInstance, Config } from "../../../config";
import Loader from "../../Common/Loader";


const Location = () => {

  const [loading, setLoading] = useState(false);
  const [location, setLocations] = useState([]);
  const [banner_detail, setBannerDetails] = useState({});
  useEffect(() => {
    getLocations();
  }, []);
  function getLocations() {
    setLoading(true);
    axiosInstance
      .get(`locationpage`)
      .then((res) => {
        setLocations(res?.data?.location_page?.location_section);
        setBannerDetails(res?.data?.location_page?.location_bg);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <div>
      {loading && <Loader />}

      <Banner
        subtitle={banner_detail?.location_title}
        title={banner_detail?.location_subtitle}
        backImage={banner_detail?.location_bg}
      />

      <div className="section-padding bg-light-gold">
        <div className="container">
          <div className="row  pb-0">
            {location?.map((obj, i) => {
              return i == 3 || i == 4 || i == 8 || i == 9 ? (
                <Link
                  key={i}
                  to={`${Config.appUrl}location/${obj.slug}`}
                  className="col-xl-6 col-lg-4 col-sm-6 mb-4 position-relative"
                >
                  <div className="details">
                    <button>Details</button>
                  </div>
                  <div className="tour-card two-card">
                    <img src={`${Config.imageUrl}/${obj.image}`} alt="" />
                    <div className="content">
                      <div className="title">{obj.city}</div>
                    </div>
                  </div>
                </Link>
              ) : (
                <Link
                  key={i}
                  to={`${Config.appUrl}location/${obj.slug}`}
                  className="col-xl-4 col-lg-4 col-sm-6 mb-4 position-relative"
                >
                  <div className="details">
                    <button>Details</button>
                  </div>
                  <div className="tour-card">
                    <img src={`${Config.imageUrl}/${obj.image}`} alt="" />
                    <div className="content">
                      <div className="title">{obj.city}</div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>

    </div>
  );
};

export default Location;
