import React from "react";
import { Config } from "../../../config";

const OurAchievement = ({ achievements  }) => {
  return (
    <div className="">
      <div className="container">
        <div className="section-padding">
          <div className="row justify-content-md-between justify-content-center">
            <div className="col-md-6 pe-md-5">
              <div className="featureprojects pb-0">
                <span className="subTitle">
                  {achievements?.hero_section_title}
                </span>
                <h2 className="text-black  ">
                  {achievements?.hero_section_bold_text}
                </h2>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: achievements?.hero_section_text,
                }}
              />
              <div className="row mt-3">
                <div className="col-md-4">
                  <div className="static-box">
                    <div className="text-center">
                      <i className="bi bi-house-heart"></i>
                    </div>
                    <h5 className="counts mt-2">
                      {achievements?.thumbnail_1_value} <br /> {achievements?.Thumbnail1}
                    </h5>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="static-box">
                    <div className="text-center">
                      <i className="bi bi-buildings"></i>
                    </div>
                    <h5 className="counts mt-2">
                      {" "}
                      {achievements?.thumbnail_2_value} <br /> {achievements?.Thumbnail_2}
                    </h5>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="static-box">
                    <div className="text-center">
                      <i className="bi bi-person-heart"></i>
                    </div>
                    <h5 className="counts mt-2">
                      {" "}
                      {achievements?.thumbnail_3_value}
                      <br /> {achievements?.Thumbnail_3}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 achivement-side">
              <div className="row">
                <div className="col-md-6 first-img sec-img">
                  <img
                    alt="No Img"
                    src={`${Config.imageUrl}/${achievements?.hero_bg}`}
                  
                  />
                </div>
                <div className="col-md-6 ">
                  <img
                    alt="No Img"
                    src={`${Config.imageUrl}/${achievements?.hero_bg2}`}
                    
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OurAchievement;
