import React, { useEffect, useState } from "react";
import Banner from "../../Common/Banner";
import { axiosInstance, Config } from "../../../config";
import Testimonials from "../Home/Testimonials";
import Loader from "../../Common/Loader";
import GetInTouch from "../../Common/GetInTouch";

const Aboutus = () => {
  const [loading, setLoading] = useState(false);
  const [about_detail, setAboutDetail] = useState({});
  const [banner_detail, setBannerDetails] = useState({});
  const [vission_section, setVissionSection] = useState([]);
  useEffect(() => {
    getAboutUs();
  }, []);
  function getAboutUs() {
    setLoading(true);
    axiosInstance
      .get(`about_us`)
      .then((res) => {
        setAboutDetail(res?.data?.aboutus_page);
        setVissionSection(res?.data?.aboutus_page?.article);
        setBannerDetails(res?.data?.aboutus_page?.about_us_bg);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }


  return (
    <div>
      {loading && <Loader />}
      <Banner
        subtitle={banner_detail?.team_section_title}
        title={banner_detail?.team_section_subtitle}
        backImage={banner_detail?.team_bg}
      />

      <div className="container">
        <div className="section-padding">
          <div className="row justify-space-between align-items-center">
            <div className="col-md-6 viewprojects   position-relative pb-0">
              <div className="row ">
                <div className="col-md-12">
                  <img
                    src={`${Config.imageUrl}/${about_detail?.about_us?.about_img}`}
                    alt="No img"
                    className="w-100 about-side-img"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="featureprojects pb-0">
                <span
                  className="subTitle"
                  dangerouslySetInnerHTML={{
                    __html: about_detail?.about_us?.about_subtitle,
                  }}
                />
                <h2 className="text-black  ">
                  {about_detail?.about_us?.about_title}
                </h2>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: about_detail?.about_us?.hero_section_text,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bd-light-gold section-padding">
        <div className="container">
          <div className="row merge-section">
            {vission_section?.map((art, i) => {
              if (i % 2 == 0) {
                return (
                  <>
                    <div className="col-md-6 pe-0" data-aos="fade-right">
                      <div className="detail-card-info">
                        <img
                          src={`${Config.imageUrl}/${art?.image}`}
                          alt="Project Detail"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 ps-0 " data-aos="fade-left">
                      <div className="box-info h-100 bd-light-gold ">
                        <h2 className="main-title">{art?.title}</h2>
                        <ul>
                          <li className="d-flex ">
                            <i className="bi bi-box-arrow-in-right me-2"></i>
                            <div
                              dangerouslySetInnerHTML={{ __html: art?.content }}
                            ></div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                );
              } else {
                return (
                  <>
                    <div className="col-md-6 pe-0 " data-aos="fade-right">
                      <div className="box-info h-100 bd-light-gold">
                        <h2 className="main-title">{art?.title}</h2>
                        <ul>
                          <li className="d-flex">
                            <i className="bi bi-box-arrow-in-right me-2"></i>{" "}
                            <div
                              dangerouslySetInnerHTML={{ __html: art?.content }}
                            ></div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6 ps-0" data-aos="fade-left">
                      <div className="detail-card-info ">
                        <img
                          src={`${Config.imageUrl}/${art?.image}`}
                          alt="Project Detail"
                        />
                      </div>
                    </div>
                  </>
                );
              }
            })}
          </div>
        </div>
      </div>

      <Testimonials
        testimonial={about_detail?.testimonial?.testimonials}
        titles={about_detail?.about_us}
        backImage={about_detail?.testimonial?.bg?.Test_bg}
      />

      <div className="section-padding" style={{ backgroundColor: "#f8f5f0" }}>
        <div className="container">
          <div className="row align-items-center justify-space-between">
            <div className="col-md-6 side-info">
              <div className="col-md-10">
                <p>{about_detail?.contact_section?.longitude}</p>
                <div className="my-4">
                  <div className="d-flex section gap-3 align-items-center mb-2">
                    <a className="icon">
                      <i className="bi bi-geo-alt"></i>
                    </a>
                    <div>{about_detail?.contact_section?.contact_addresses}</div>
                  </div>
                  <div className="d-flex section gap-3 align-items-center mb-2">
                    <a className="icon">
                      <i className="bi bi-telephone"></i>
                    </a>
                    <div>
                      <a
                        className="text-dark text-decoration-none"
                        href={`callto:${about_detail?.contact_section?.contact_numbers}`}
                      >
                        {about_detail?.contact_section?.contact_numbers}
                      </a>
                    </div>
                  </div>
                  <div className="d-flex section gap-3 align-items-center  mb-2">
                    <a className="icon">
                      <i className="bi bi-envelope"></i>
                    </a>
                    <div>
                      <a
                        className="text-dark text-decoration-none"
                        href={`mailto:${about_detail?.contact_section?.contact_mails}`}
                      >
                        {about_detail?.contact_section?.contact_mails}
                      </a>
                    </div>
                  </div>
                </div>

                <div>
                  <h4>Working Hours</h4>
                  <h6 className="fw-bold">{about_detail?.contact_section?.working_days}</h6>
                  <h6 className="fw-bold">{about_detail?.contact_section?.working_hours}</h6>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <GetInTouch section={'About Us'} />
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default Aboutus;
