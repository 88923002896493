import React, { useEffect, useState } from "react";
import Banner from "../../Common/Banner";
import ProjectCard from "../../Common/ProjectCard";
import { axiosInstance } from "../../../config";
import Loader from "../../Common/Loader";
import Pagination from "../../Common/Pagination";
import GetInTouch from "../../Common/GetInTouch";

const AllProjects = () => {
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [bannerDetails, setBannerDetails] = useState({});

  useEffect(() => {
    getProjects();
  }, []);
  function getProjects() {
    setLoading(true);
    axiosInstance
      .get(`all-projects`)
      .then((res) => {
        setProjects(res?.data?.project_page?.project_info);
        setBannerDetails(res?.data?.project_page?.project_bg);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }

  const [page, setPage] = useState(1);
  const [items_perpage, setItemsPerPage] = useState(9);
  const total_pages = Math.ceil(projects?.length / items_perpage);
  const final_data = projects?.slice(
    page * items_perpage - items_perpage,
    page * items_perpage
  );
  const changePage = (pagecount) => {
    if (pagecount > 0 && pagecount <= total_pages) {
      setPage(pagecount);
      // window.scrollTo(0, 400);
      window.scrollTo({
        top: 400,
        left: 0,
        behavior: "instant", // Optional if you want to skip the scrolling animation
      });
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <Banner
        subtitle={bannerDetails?.project_title}
        title={bannerDetails?.project_subtitle}
        backImage={bannerDetails?.project_img}
      />
      <div className="">
        <div className=" bgblack">
          <div className="container">
            <div className="section-padding">
              <div className="featureprojects">
                <div className="projects">
                  <ProjectCard projectsData={final_data} />
                </div>
              </div>
              <Pagination
                final_data={final_data}
                blogs={projects}
                total_pages={total_pages}
                page={page}
                changePage={changePage}
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="section-padding" style={{ backgroundColor: "#f8f5f0" }}>
        <div className="container">
          <div className="row align-items-center justify-space-between">
            <div className="col-md-6 side-info">
              <div className="col-md-10">
                <p>{about_detail?.contact_section?.longitude}</p>
                <div className="my-4">
                  <div className="d-flex section gap-3 align-items-center mb-2">
                    <a className="icon">
                      <i className="bi bi-geo-alt"></i>
                    </a>
                    <div>{about_detail?.contact_section?.contact_addresses}</div>
                  </div>
                  <div className="d-flex section gap-3 align-items-center mb-2">
                    <a className="icon">
                      <i className="bi bi-telephone"></i>
                    </a>
                    <div>
                      <a
                        className="text-dark text-decoration-none"
                        href={`callto:${about_detail?.contact_section?.contact_numbers}`}
                      >
                        {about_detail?.contact_section?.contact_numbers}
                      </a>
                    </div>
                  </div>
                  <div className="d-flex section gap-3 align-items-center  mb-2">
                    <a className="icon">
                      <i className="bi bi-envelope"></i>
                    </a>
                    <div>
                      <a
                        className="text-dark text-decoration-none"
                        href={`mailto:${about_detail?.contact_section?.contact_mails}`}
                      >
                        {about_detail?.contact_section?.contact_mails}
                      </a>
                    </div>
                  </div>
                </div>

                <div>
                  <h4>Working Hours</h4>
                  <h6 className="fw-bold">{about_detail?.contact_section?.working_days}</h6>
                  <h6 className="fw-bold">{about_detail?.contact_section?.working_hours}</h6>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <GetInTouch section={'All Projects'} />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default AllProjects;
