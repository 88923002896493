import React, { useEffect, useState } from "react";
import Banner from "../../Common/Banner";
import { axiosInstance } from "../../../config";
import Loader from "../../Common/Loader";
import GetInTouch from "../../Common/GetInTouch";
import mapImage from "../../../assets/Images/India-map.png";
import LocationAlt from "../../../assets/Images/location-alt.png";


const ContactUs = () => {
  const [loading, setLoading] = useState(false);

  const [contact_details, setContactDetails] = useState([]);
  const [locations, setLocations] = useState([]);
  const [bannrDetail, setBanerDetail] = useState({});
  const [socialLinks, setSocialLinks] = useState([]);
  useEffect(() => {
    getContactUs();
  }, []);
  function getContactUs() {
    setLoading(true);
    axiosInstance
      .get(`contactpage`)
      .then((res) => {
        setContactDetails(res?.data?.contact_page?.contact_section);
        setBanerDetail(res?.data?.contact_page?.contact_bg);
        const locations =
          res?.data?.contact_page?.contact_section?.Text_1?.split(",");
        const locationsNo =
          res?.data?.contact_page?.contact_section?.Value_1?.split(",");
        const LocationEmail = res?.data?.contact_page?.contact_section?.Text_2?.split(",");
        const Locationaddress = res?.data?.contact_page?.contact_section?.Value_2?.split("|");
        const result = locations.map((name, index) => ({
          name: name,
          no: locationsNo[index],
          email: LocationEmail[index],
          address: Locationaddress[index]
        }));

        const footerIcons = res?.data?.contact_page?.footer_section?.icon?.split(",");
        const footerLink = res?.data?.contact_page?.footer_section?.link?.split(",");
        const merge = footerIcons.map((name, index) => ({
          icon: name,
          link: footerLink[index],
        }));
        setSocialLinks(merge);
        setLocations(result);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }


  return (
    <div>
      {loading && <Loader />}
      
      <Banner
        subtitle={bannrDetail?.contact_subtitle}
        title={bannrDetail?.contact_title}
        backImage={bannrDetail?.contactus_bg}
      />

      <div className="section-padding" style={{ backgroundColor: "#f8f5f0" }}>
        <div className="container">
          <div className="row align-items-center justify-space-between ">
            <div className="col-md-6 side-info">
              <div className="col-md-10">
                {/* <span className="subTitle">Headquaters Address</span> */}
                <p className="fw-bold">{contact_details?.longitude}</p>
                <div className="my-4">
                  <div className="d-flex section gap-3 align-items-center mb-2">
                    <a className="icon">
                      <i className="bi bi-geo-alt"></i>
                    </a>
                    <div >{contact_details?.contact_addresses}</div>
                  </div>
                  <div className="d-flex section gap-3 align-items-center mb-2">
                    <a className="icon">
                      <i className="bi bi-telephone"></i>
                    </a>
                    <div>
                      <a
                        className="text-dark text-decoration-none"
                        href={`callto:${contact_details?.contact_numbers}`}
                      >
                        {contact_details?.contact_numbers}
                      </a>
                    </div>
                  </div>
                  <div className="d-flex section gap-3 align-items-center  mb-2">
                    <a className="icon">
                      <i className="bi bi-envelope"></i>
                    </a>
                    <div>
                      <a
                        className="text-dark text-decoration-none"
                        href={`mailto:${contact_details?.contact_mails}`}
                      >
                        {contact_details?.contact_mails}
                      </a>
                    </div>
                  </div>
                </div>

                <div >
                  <h4>Working Hours</h4>
                  <h6 className="fw-bold">{contact_details?.working_days}</h6>
                  <h6 className="fw-bold">{contact_details?.working_hours}</h6>
                </div>
              </div>
              <div className="row mt-4 flex-column">
                <div className="d-flex gap-2">

                  {socialLinks?.map((social, i) => {
                    return (
                      <div>
                        <a className="social-icons" href={social?.link}>
                          <i className={social?.icon}></i>
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <GetInTouch section={'Contact Us'} />
            </div>
          </div>

          <div className="mt-5 pt-5">
            <div className="featureprojects pb-0">
              {/* <span className="subTitle">Our Locations</span> */}
              <h2 className="text-black  ">Our Other Branches</h2>
              <div className="col-md-12 other-branches  position-relative">
                <div className="row justify-content-s align-items-center ">
                  <div className="masp col-md-6">
                    <div className="position-relative">
                      <img src={mapImage} className="map-img" alt="" width='450' />
                      <div className="geo-location first">
                        <img src={LocationAlt} alt=""></img>
                      </div>
                      <div className="geo-location second">
                        <img src={LocationAlt} alt=""></img>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    {locations?.map((obj, i) => {
                      return (
                        <div className="col-md-12" key={i + 1}>
                          <div className="static-box  bg-white mb-2 contact-list">
                            <div className=" d-flex justify-content-between align-items-center w-100">
                              <h5 className="counts mb-0">{obj?.name} :</h5>
                              <p className="mb-0">
                                <i className="bi bi-telephone"></i> {obj?.no}
                              </p>
                            </div>
                            <div className="d-flex section gap-3 align-items-center mb-2 mt-3">
                              <a className="icon">
                                <i className="bi bi-geo-alt"></i>
                              </a>
                              <div>{obj?.address}</div>
                            </div>
                            <div className="d-flex section gap-3 align-items-center mb-2">
                              <a className="icon">
                                <i className="bi bi-envelope"></i>
                              </a>
                              <div>{obj?.email}</div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="first-location line-top"></div>
                <div className="second-location line-tops"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
