export const slugGenerator = (text) => {
  try {
    return text.toLowerCase().replace(/\s+/g, "-");
  } catch (error) {
    return text;
  }
};

export const revertSlug = (slug) => {
    try {
      // Replace hyphens with spaces
      const textWithSpaces = slug.replace(/-/g, " ");
      
      // Capitalize the first letter of each word
      return textWithSpaces
        .toLowerCase()
        .replace(/\b\w/g, (char) => char.toUpperCase());
    } catch (error) {
      return slug;
    }
  };
  