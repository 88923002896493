import React from "react";
import "../../assets/css/Banner.css";
import Image3 from "../../assets/Images/image-3.jpg";
import { Config } from "../../config";

const Banner = ({ subtitle, title, backImage }) => {
  return (
    <div>
      <div
        className="banner-header section-padding valign bg-img bg-fixed"
        data-overlay-dark="4"
        data-background="img/slider/1.jpg"
        style={{
          backgroundImage: `url(${
            backImage ? `${Config.imageUrl}/${backImage}` : Image3
          })`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="">
          <div className="container">
            <div className="row banner-top">
              <div className="col-md-12 caption mt-90">
                <h5>{subtitle}</h5>
                <h1>{title}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
