import React from "react";
import ProjectCard from "../../Common/ProjectCard";
import { Link } from "react-router-dom";
import { Config } from "../../../config";

const FeatureProjects = ({ projects, projectdetail}) => {

  return (
    <div className=" bgblack">
      <div className="container">
        <div className="section-padding pb-3">
          <div className="featureprojects">
            {/* <span className="subTitle">Hotel Blog</span> */}
            <h2>{projectdetail?.project_text}</h2>
            <div className="projects">
              <ProjectCard projectsData={projects?.reverse()?.slice(0,3)} />
              <div className="viewallprojects">
                <Link to={`${Config.appUrl}all-projects`}>
                  <button>{projectdetail?. project_view_button}</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FeatureProjects;
