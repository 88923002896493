import React from "react";
import GetInTouch from "./GetInTouch";

const CTA = ({ contactSecton, section }) => {
  return (
    <div>
      <div className="section-padding" style={{ backgroundColor: "#f8f5f0" }}>
        <div className="container">
          <div className="row align-items-center justify-space-between">
            <div className="col-md-6 side-info">
              <div className="col-md-10">
                <p>{contactSecton?.longitude}</p>
                <div className="d-flex section gap-3 align-items-center mb-2">
                  <a className="icon">
                    <i className="bi bi-geo-alt"></i>
                  </a>
                  <div>{contactSecton?.contact_addresses}</div>
                </div>
                <div className="d-flex section gap-3 align-items-center mb-2">
                  <a className="icon">
                    <i className="bi bi-telephone"></i>
                  </a>
                  <div>
                    <a
                      className="text-decoration-none text-dark"
                      href={`callto:${contactSecton?.contact_numbers}`}
                    >
                      {contactSecton?.contact_numbers}
                    </a>
                  </div>
                </div>
                <div className="d-flex section gap-3 align-items-center  mb-2">
                  <a className="icon">
                    <i className="bi bi-envelope"></i>
                  </a>
                  <div>
                    <a
                      className="text-decoration-none text-dark"
                      href={`mailto:${contactSecton?.contact_mails}`}
                    >
                      {contactSecton?.contact_mails}
                    </a>
                  </div>
                </div>
                <div className="mt-3">
                  <h4>Working Hours</h4>
                  <h6>{contactSecton?.working_days}</h6>
                  <h6>{contactSecton?.working_hours}</h6>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <GetInTouch section={section}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTA;
