import React from "react";
import { Config } from "../../../config";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const ViewProjects = ({ projects, title }) => {
  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="div-container bg-cream">
      <div className="viewprojects ">
        <h2>{title?.all_project_text}</h2>
        <div className="row">
          <Slider {...settings}>
            {projects?.map((project) => (
              <div key={project.id} className="col-md-4 pe-2 ps-2">
                <Link to={`${Config.appUrl}category/${project.slug}`}>
                  <div
                    className={`item`}
                    style={{
                      backgroundImage: `url(${Config.imageUrl}/${project.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      height: "550px",
                      position: "relative",
                    }}
                  >
                    <div className="details">
                      <button>Details</button>
                    </div>

                    <div className="overlay"></div>
                    <div className="title">{project.name}</div>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};
export default ViewProjects;
