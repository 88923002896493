import { Modal, notification } from "antd";
import React, { useState } from "react";
import { axiosInstance, Config } from "../../config";
import Loader from "./Loader";

const ContactModal = ({ type, project, onClose, section }) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});


  const validation1 = () => {
    const newErrors = {};

    if (!name) {
      newErrors.fname = "Name is required";
    }

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      newErrors.email = "Invalid email format";
    }

    if (!phone) {
      newErrors.phone = "Phone Number is required";
    } else if (phone.length > 15 || phone.length < 7) {
      newErrors.phone = "Invalid Phone Number length";
    }

    if (!message) {
      newErrors.health_problem = "Message is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    if (validation1()) {
      setLoading(true);
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("name", name);
        formData.append("phone", phone);
        formData.append("email", email);
        formData.append("message", message);
        formData.append("type", type);
        formData.append("section", section);
        formData.append("project", project != undefined ? project : '');
        const res = await axiosInstance.post("contactdetails", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        });
        if (res.status === 200) {
          window.open(
            `${Config.pdfUrl}/${res?.data?.downloadLink}`,
            '_blank'
          );
          setName("");
          setPhone("");
          setMessage("");
          setEmail("");
          notification.open({
            message: (
              <div className="d-flex align-items-center">
                <div className="pr-20">
                  <i
                    className="bi bi-check2-circle text-success me-2"
                    style={{ fontSize: "20px" }}
                  ></i>
                </div>
                <div className="succes-message">Thanks for reaching out to us! </div>
              </div>
            ),
            description: "We will contact you with a response shortly.",
          });
          onClose()
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      {loading && (<Loader />)}
      <div className="w-100 mt-4">
        <form onSubmit={onHandleSubmit}>
          <div className="">
            <div className="mb-3">
              <input
                type="text"
                placeholder="Enter Full Name *"
                className=" form-luxury bg-white"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                onKeyPress={(e) => {
                  if (/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
              {errors.fname && (
                <span className=" text-danger span-error">
                  {errors.fname}
                </span>
              )}
            </div>
            <div className="mb-3">
              <input
                type="email"
                placeholder="Enter Email Address *"
                className=" form-luxury  bg-white"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              {errors.email && (
                <span className=" text-danger span-error">
                  {errors.email}
                </span>
              )}
            </div>
            <div className="mb-3">
              <input
                type="text"
                placeholder="Enter Phone Number *"
                className=" form-luxury  bg-white"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
              {errors.phone && (
                <span className=" text-danger span-error">
                  {errors.phone}
                </span>
              )}
            </div>
            <div className="mb-3">
              <textarea
                type="text"
                placeholder="Enter Message *"
                className=" form-luxury  bg-white"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
              {errors.message && (
                <span className=" text-danger span-error">
                  {errors.message}
                </span>
              )}
            </div>
            <div>
              <button className="submit-btn">Send Message</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactModal;
