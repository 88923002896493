import React from "react";
import "../../assets/css/Loader.css";

const Loader = () => {
  return (
    <div className="spinner-loader">
      <div className="loader"></div>
    </div>
  );
};

export default Loader;
