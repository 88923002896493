import React from 'react'
import { InlineWidget } from 'react-calendly'

const CalendlyPopup = () => {
    const pageSettings = {
        backgroundColor: 'ffffff',
        hideEventTypeDetails: false,
        hideLandingPageDetails: false,
        primaryColor: '00a2ff',
        textColor: '4d5055',
        margin: '0'
    }

    return (
        <div>
            <InlineWidget url="https://calendly.com/john-zithas/schedule-a-visit" styles={{ height: '685px', marginTop: '-75px' }} data-cookie-policy="none" pageSettings={pageSettings} />
        </div>
    )
}

export default CalendlyPopup
