import React, { useState, useEffect } from "react";
import Logo from "../../assets/Images/Uber_Luxury_Connect_logo__1_-removebg-preview.png";
import { Link, NavLink, useLocation } from "react-router-dom";
import "../../assets/css/Home.css";
import { axiosInstance, Config } from "../../config";

const Navbar = ({ pageName }) => {
  const [scrolled, setScrolled] = useState(false);

  const browser_location = useLocation()

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [browser_location.pathname]);

  function openNav() {
    document.getElementById("menus-m").style.height = "400px";
  }

  function closeNav() {
    document.getElementById("menus-m").style.height = "0";
  }

  const location = useLocation();

  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (status?.length == 0) {
      getStatus();
    }
  }, [status]);

  function getStatus() {
    setLoading(true);
    axiosInstance
      .get(`project_status`)
      .then((res) => {
        setStatus(res?.data?.status_page?.all_status);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="container">
      <div className={`navbar`}>
        <Link to={`${Config.appUrl}`}>
          <div className="d-flex align-items-center gap-3">
            <img src={Logo} alt="No Img" />
            <h3 className="mb-0">Uber Luxury Connect</h3>
          </div>
        </Link>
        <ul className="sub-menus">
          <li>
            <Link
              to={`${Config.appUrl}`}
              className={`${
                location.pathname == Config.appUrl ? "active" : ""
              }`}
            >
              HOME
            </Link>
          </li>
          <li>
            <Link
              to={`${Config.appUrl}about-us`}
              className={`${
                location.pathname == Config.appUrl + "about-us" ? "active" : ""
              }`}
            >
              ABOUT US
            </Link>
          </li>
          <li className="ps-projects position-relative">
            <Link to="#">
              PROJECTS <i className="bi bi-chevron-down"></i>
            </Link>
            <div className="ps-dropdown">
              <div className="ps-items">
                <Link to={`${Config.appUrl}all-projects`}>All Projects</Link>
                {status?.map((obj, i) => {
                  return (
                    <Link
                      to={`${Config.appUrl}status/${obj.slug}`}
                      key={obj.slug}
                    >
                      {obj?.name}
                    </Link>
                  );
                })}
              </div>
            </div>
          </li>
          <li>
            <Link
              to={`${Config.appUrl}locations`}
              className={`${
                location.pathname == Config.appUrl + "locations" ? "active" : ""
              }`}
            >
              LOCATIONS
            </Link>
          </li>

          <li>
            <Link to={`${Config.appUrl}blogs`}>BLOGS</Link>
          </li>
          <li>
            <Link
              to={`${Config.appUrl}contact-us`}
              className={`${
                location.pathname == Config.appUrl + "contact-us"
                  ? "active"
                  : ""
              }`}
            >
              CONTACT US
            </Link>
          </li>
        </ul>
        <div className="sidebar-icon">
          <a onClick={openNav}>
            <i className="bi bi-list"></i>
          </a>
        </div>
        <div className="mobile-menu" id="menus-m">
          <div className="main-div position-relative">
            <div>
              <a className="cancel-button" onClick={closeNav}>
                <i className="bi bi-x-lg"></i>
              </a>
            </div>
            <a className="mt-3 mb-3">
              <img src={Logo} alt="Logo" className="me-2" />
              Uber Luxury Connects
            </a>
            <Link to={`${Config.appUrl}`}>
              <i className="bi bi-arrow-right  me-2"></i>HOME
            </Link>
            <Link to={`# `} className="projects">
              <i className="bi bi-arrow-right  me-2"></i>All PROJECTS
              <div className="mobile-seen">
                <ul>
                  <li>
                    <Link to={`${Config.appUrl}all-projects`}>
                      <i className="bi bi-caret-right-fill"></i> All Projects
                    </Link>
                  </li>
                  {status?.map((obj, i) => {
                    return (
                      <li key={i}>
                        <Link
                          to={`${Config.appUrl}status/${obj.slug}`}
                          key={obj.slug}
                        >
                          <i className="bi bi-caret-right-fill"></i> {obj?.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Link>
            <Link to={`${Config.appUrl}locations`}>
              <i className="bi bi-arrow-right  me-2"></i>LOCATIONS
            </Link>
            <Link to={`${Config.appUrl}about-us`}>
              <i className="bi bi-arrow-right  me-2"></i>ABOUT US
            </Link>
            <Link to={`${Config.appUrl}blogs`}>
              <i className="bi bi-arrow-right  me-2"></i>BLOGS
            </Link>
            <Link to={`${Config.appUrl}contact-us`}>
              <i className="bi bi-arrow-right  me-2"></i>CONTACT US
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
