import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Config } from "./config";
import Index from "./Components/Pages/Home/Index";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import Location from "./Components/Pages/Location/Location";
import LocationProject from "./Components/Pages/Project/Project";
import ProjectDetail from "./Components/Pages/Project/ProjectDetail";
import ContactUs from "./Components/Pages/Contact/ContactUs";
import Aboutus from "./Components/Pages/About/Aboutus";
import Blog from "./Components/Pages/Blogs/Blog";
import BlogDetails from "./Components/Pages/Blogs/BlogDetails";
import AllProjects from "./Components/Pages/Project/AllProjects";
import CategoryProjects from "./Components/Pages/Project/CategoryProjects";
import StatusProjects from "./Components/Pages/Project/StatusProject";
import Footer from "./Components/Common/Footer";
import Navbar from "./Components/Common/Navbar";

import copy from 'copy-to-clipboard';

function App() {
  useEffect(() => {
    AOS.init({
      offset: 100, // offset (in px) from the original trigger point
      duration: 800, // values from 0 to 3000, with step 50ms
      easing: "ease-out",
      once: false,
    });
  }, []);

  // disable inspect
  useEffect(() => {
    const handleKeyPress = (event) => {
      // console.log(event)
      const keys = ["Control", "Shift", "I", "S", "F12", "PrintScreen"]
      if (keys.includes(event.key)) {
        event.preventDefault();
        navigator.clipboard.writeText('Screenshot disabled')
          .then(item => console.log('Screenshot disabled'))
          .catch(err => console.log(err))
      }
    };
    window.addEventListener('keydown', handleKeyPress);

    // Disable right-click on the entire document
    const handleRightClick = (event) => {
      event.preventDefault();  // Prevent the context menu from appearing
    };
    document.addEventListener('contextmenu', handleRightClick);

    // prevent ScreenShot
    const handlePreventScreenShot = async (event) => {
      try {
        if (event.key === "PrintScreen") {
          // console.log(event)
          // navigator.clipboard.writeText('')
          // .then(item => console.log('Screenshot disabled'))
          // .catch(err => alert('Error preventing Screenshot'))
          alert("Screenshot Disabled")
          copy("Screenshot disabled")
          // window.open('https://www.google.com', "_blank")
          // alert('Screenshot disabled');
        }
      } catch (error) {
        console.log(error)
      }
    };
    document.addEventListener('keyup', handlePreventScreenShot);


    return () => {
      window.removeEventListener('keydown', handleKeyPress);
      document.removeEventListener('contextmenu', handleRightClick);
      document.removeEventListener('keyup', handlePreventScreenShot);
    };
  }, []);

  return (
    <div >
      <Router>
        <Navbar />
        <Routes>
          <Route path={`${Config.appUrl}`} Component={Index} />
          <Route path={`${Config.appUrl}locations`} Component={Location} />
          <Route
            path={`${Config.appUrl}location/:projects`}
            Component={LocationProject}
          />
          <Route
            path={`${Config.appUrl}all-projects`}
            Component={AllProjects}
          />
          <Route
            path={`${Config.appUrl}category/:projects`}
            Component={CategoryProjects}
          />
          <Route
            path={`${Config.appUrl}status/:projects`}
            Component={StatusProjects}
          />
          <Route
            path={`${Config.appUrl}project/:name`}
            Component={ProjectDetail}
          />
          <Route path={`${Config.appUrl}contact-us`} Component={ContactUs} />
          <Route path={`${Config.appUrl}about-us`} Component={Aboutus} />
          <Route path={`${Config.appUrl}blogs`} Component={Blog} />
          <Route path={`${Config.appUrl}blog-detail/:slug`} Component={BlogDetails} />
        </Routes>

        <Footer />

      </Router>
    </div>
  );
}

export default App;
