import React from "react";
import { Link } from "react-router-dom";
import { Config } from "../../../config";
import NoProject from "../../../assets/Images/image-not-found.png"

const index = ({ projectsData }) => {
  return (
    <div className="row">
      {projectsData?.map((project, index) => (
        <div className="col-md-4 position-relative mb-100 ">
          <Link
            to={`${Config.appUrl}project/${project.slug}`}
            className="particular-project text-black"
          >
            <div className="project-overlay"> </div>
            <div className="project-date">
              <span>{project?.status_name}</span>
            </div>
            <div className="proj-box">
              <img
                src={`${project?.project_thumbnail  != null ? `${Config.imageUrl}/${project.project_thumbnail }` : NoProject}`}
                alt={`Project`}
              />
              <div className="proj-text">
                <div className="proj-title">
                  <div className="proj-name">{project.project_name}</div>
                  <span>{project?.project_loc	}</span>
                </div>
                <div className="area-section">
                  <div>
                    <i className="bi bi-check-all"></i>&nbsp; {project?.size}
                  </div>
                  <div>
                    <i className="bi bi-check-all"></i>&nbsp;{" "}
                    {project?.configuration}
                  </div>
                  <div>
                    <i className="bi bi-check-all"></i>&nbsp;{" "}
                    {project?.project_status_data}
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))}
      {projectsData?.length == 0 && (
        <div>
          {/* <img src={NoProject} /> */}
          <p className="text-white">No Projects available.</p>
        </div>
      )}
    </div>
  );
};

export default index;
